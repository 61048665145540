import React from 'react';
import { graphql } from "gatsby";
import Layout from 'components/layout';
import {Breadcrumb, Button, Hero} from 'components/ui';
import SeoComponent from 'components/seo';
import TwoColumnContent from "templates/two-column-content-layout.js";
import ToolkitModuleHeroImage from "assets/images/hero-training-toolkit.jpg"

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const TrainingToolkitDetail = ({location, data}) => {
    const toolkitModule = data?.strapiToolkitModule;
    const seo = {
        metaTitle: toolkitModule?.Title,
        metaDescription: toolkitModule?.description,
        metaKeywords: toolkitModule?.keywords
      }
    let thisLocationPathname = ''

    if (isBrowser) {
        thisLocationPathname = window.location.pathname
    }
    const moduleName=filename=>{
      var moduleNameSlug = "";
      if((filename.includes("module-1") || filename.includes("mod1")) && !filename.includes("module-10")){
        moduleNameSlug = "mod1"
      } else if(filename.includes("module-2") || filename.includes("mod2")){
        moduleNameSlug = "mod2"
      } else if(filename.includes("module-3") || filename.includes("mod3")){
        moduleNameSlug = "mod3"
      } else if(filename.includes("module-4") || filename.includes("mod4")){
        moduleNameSlug = "mod4"
      } else if (filename.includes("module-5") || filename.includes("mod5")){
        moduleNameSlug = "mod5"
      } else if (filename.includes("module-6") || filename.includes("mod6")){
        moduleNameSlug = "mod6"
      } else if (filename.includes("module-7") || filename.includes("mod7")){
        moduleNameSlug = "mod7"
      } else if (filename.includes("module-8") ||filename.includes("topic-meth")){
        moduleNameSlug = "topic-meth"
      } else if(filename.includes("module-9") || filename.includes("topic-opioids")){
        moduleNameSlug = "topic-opioids"
      } else if(filename.includes("module-10") || filename.includes("topic-prenatal")){
        moduleNameSlug = "topic-prenatal"
      }else {
        moduleNameSlug = "misc"
      }
      return moduleNameSlug;
  }

return(
   <Layout location={location}>
        <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
        <Hero
            img={ToolkitModuleHeroImage}
            color="#f5f5f5"
            isFixedHeight={false}
            >
              <Breadcrumb pathname={thisLocationPathname} customCurrentPage={toolkitModule?.card_title ?? toolkitModule?.Title} />
              {toolkitModule?.Title && <h1 name="main-content" id="main-content" className="usa-hero__heading">{toolkitModule?.Title}</h1>}
          </Hero>
        <TwoColumnContent>
            <TwoColumnContent.ColumnOne>
            {toolkitModule?.Description_and_Objectives?.data &&
                <div className="margin-bottom-7" id="toolkit-content">
                    <h2>Description and Objectives</h2>
                        <div
                        dangerouslySetInnerHTML={{
                            __html: toolkitModule?.Description_and_Objectives.data?.childMarkdownRemark?.html
                            }}
                        className="unordered-list-style"
                        />
                </div>
                }
                {/* {toolkitModule?.Training?.data &&
                <div className="margin-bottom-1">
                    <h2>Training Tips</h2>
                        <div
                        dangerouslySetInnerHTML={{
                            __html: toolkitModule?.Training?.data?.childMarkdownRemark?.html
                            }}
                        className="unordered-list-style"
                        />
                </div>
                }
                {toolkitModule?.Materials?.data &&
                <div className="margin-bottom-7">
                    <h2>Materials</h2>

                        <div
                        dangerouslySetInnerHTML={{
                            __html: toolkitModule?.Materials?.data?.childMarkdownRemark?.html
                            }}
                        className="unordered-list-style"
                        />
                </div>
                } */}
            </TwoColumnContent.ColumnOne>
            <TwoColumnContent.ColumnTwo>
              <div className="desktop:margin-left-5 desktop:margin-top-8 display-flex flex-column flex-align-start">
                {toolkitModule?.facilitator_guide && <p><a href={`/files/toolkitpackage/${moduleName(toolkitModule?.facilitator_guide?.name)}/${toolkitModule?.facilitator_guide?.name}`} target="_blank" rel="noreferrer" aria-label={`Open ${toolkitModule?.Title} facilitator's guide in a new window`}>Facilitator's Guide</a> (PDF {toolkitModule?.facilitator_guide?.localFile?.prettySize.toUpperCase()})</p>}
                {toolkitModule?.presentation && <p><a href={`/files/toolkitpackage/${moduleName(toolkitModule?.presentation?.name)}/${toolkitModule?.presentation?.name}`} target="_blank" rel="noreferrer" aria-label={`Open ${toolkitModule?.Title} PDF presentation in a new window`}>Presentation</a> (PDF {toolkitModule?.presentation?.localFile?.prettySize.toUpperCase()})</p>}
                {toolkitModule?.powerpoint && <p><a href={`/files/toolkitpackage/${moduleName(toolkitModule?.powerpoint?.name)}/${toolkitModule?.powerpoint?.name}`} target="_blank" rel="noreferrer" aria-label={`Download ${toolkitModule?.Title} powerpoint presentation`}>Presentation</a> (PPT {toolkitModule?.powerpoint?.localFile?.prettySize.toUpperCase()})</p>}
                {toolkitModule?.full_module && <p><a href={`/files/toolkitpackage/${moduleName(toolkitModule?.full_module?.name)}/${toolkitModule?.full_module?.name}`} target="_blank" rel="noreferrer" className="usa-button primary-button" aria-label={`Download ${toolkitModule?.Title} full module`}>Download Full Module (ZIP {toolkitModule?.full_module?.localFile?.prettySize.toUpperCase()})</a></p>}
              </div>
            </TwoColumnContent.ColumnTwo>
        </TwoColumnContent>
    </Layout>
)};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiToolkitModule(slug: { eq: $slug }) {
        Title
        slug
        description
        keywords
        Training {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        Materials {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        Description_and_Objectives {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        facilitator_guide {
          name
          localFile {
            prettySize
          }
        }
        full_module {
          name
          localFile {
            prettySize
          }
        }
        powerpoint {
          name
          localFile {
            prettySize
          }
        }
        presentation {
          name
          localFile {
            prettySize
          }
        }
      }
    }
`

export default TrainingToolkitDetail;
